import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ForbiddenPage() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        403 - Forbidden
      </Typography>
      <Typography variant="body1" gutterBottom>
        You aren't logged in with the correct credentials! To access this page, please log in with the relevant email and password.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Or, if you think this redirect is a mistake, please contact us.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/')}>
        Go to Homepage
      </Button>
    </Container>
  );
}

export default ForbiddenPage;