import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography, Grid, Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AuthService from "../../../clientUtils/auth";

const NftCard = ({ nft, onUpdateNft }) => {
  const [purchasedAt, setPurchasedAt] = useState("");

  useEffect(() => {
    const formattedPurchaseDate = new Date(nft.createdAt);
    const formattedString =
      formattedPurchaseDate.toDateString() +
      " " +
      formattedPurchaseDate.getHours().toString().padStart(2, "0") +
      ":" +
      formattedPurchaseDate.getMinutes().toString().padStart(2, "0");

    setPurchasedAt(formattedString);
  }, [nft.createdAt]);

  const handleMarkTradeable = async () => {
    try {
      const response = await fetch(`/api/nft/trade/${nft._id}/mark-tradeable`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${AuthService.getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to mark NFT as tradeable');
      }
      
      const updatedNft = await response.json();
      // Preserve the signedUrl if it's not included in the response
      updatedNft.signedUrl = updatedNft.signedUrl || nft.signedUrl;
      onUpdateNft(updatedNft);
    } catch (error) {
      console.error('Error marking NFT as tradeable:', error);
    }
  };

  const handleRemoveFromCatalogue = async () => {
    try {
      const response = await fetch(`/api/nft/trade/${nft._id}/remove-from-catalogue`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${AuthService.getToken()}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to remove NFT from catalogue');
      }
      
      const updatedNft = await response.json();
      // Preserve the signedUrl if it's not included in the response
      updatedNft.signedUrl = updatedNft.signedUrl || nft.signedUrl;
      onUpdateNft(updatedNft);
    } catch (error) {
      console.error('Error removing NFT from catalogue:', error);
    }
  };

  return (
    <Card sx={{ display: "flex", marginBottom: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image={nft.signedUrl}
            alt={nft.title}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CardContent>
            <Typography variant="h5">{nft.title}</Typography>

            {nft.verified && (
              <Box sx={{ display: "flex", alignItems: "center", color: "green", mt: 2 }}>
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography variant="body1">Verified Purchase</Typography>
              </Box>
            )}

            <Typography variant="body1">Series: {nft.series}</Typography>
            <Typography variant="body1">Edition: {nft.individual}</Typography>
            <Typography variant="body2">{nft.description}</Typography>
            <Typography variant="body2">Purchased: {purchasedAt}</Typography>

            <Box sx={{ mt: 2 }}>
              {nft.isTradeable ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    startIcon={<CheckCircleIcon />}
                    sx={{ mr: 2, opacity: 0.7 }}
                  >
                    Already marked as Tradeable
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleRemoveFromCatalogue}
                  >
                    Remove from catalogue
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMarkTradeable}
                >
                  Mark as Tradeable in the Catalogue
                </Button>
              )}
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NftCard;