import React, { useState, useEffect } from "react";
import { Container, Grid, Card, CardContent, CardMedia, Typography, Button, createTheme, ThemeProvider, Box, Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppAppBar from "../../GeneralComponents/AppAppBar";
import Footer from "./components/Footer.jsx";
import Hero from "./components/Hero.jsx";
import Theme from "../../GeneralComponents/Theme";
import AuthService from "../../clientUtils/auth";

const BrowsePage = () => {
    const [mode, setMode] = useState("light");
    const theme = createTheme(Theme(mode));
    const navigate = useNavigate();
    const [nfts, setNfts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    };

    useEffect(() => {
        if (!AuthService.loggedIn()) {
            navigate('/login');
            return;
        }

        fetchNfts(currentPage);
    }, [currentPage]);

    const fetchNfts = async (page) => {
        const token = AuthService.getToken();
        try {
            const response = await fetch(`/api/nft/tradeable?page=${page}&limit=20`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setNfts(data.nfts);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Failed to fetch NFTs", error);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Hero />
            <Container maxWidth="lg" sx={{ my: 4 }}>
                <Grid container spacing={4}>
                    {nfts.map((nft) => (
                        <Grid item xs={12} sm={6} md={4} key={nft.uuid}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transition: 'transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={nft.imageUrl}
                                    alt={nft.title}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        {nft.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                        Series: {nft.series} | Edition: {nft.individual}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        {nft.description}
                                    </Typography>
                                    <Box sx={{ mt: 'auto' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => navigate(`/trade/${nft.uuid}/null`)} // Include placeholder tradeId
                                        >
                                            Trade
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Pagination 
                        count={totalPages} 
                        page={currentPage} 
                        onChange={handlePageChange} 
                        color="primary" 
                    />
                </Box>
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default BrowsePage;