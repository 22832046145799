import React, { useState } from "react";
import Button from "@mui/material/Button";
import AuthService from "../../../clientUtils/auth";
import { useNavigate } from "react-router-dom";

const AddToWallet = ({ uuid }) => {
  let navigate = useNavigate();
  const [isAdded, setIsAdded] = useState(false);

  const handleButtonClick = async () => {
    if (isAdded) {
      // If the NFT is already added, navigate to the user's account
      navigate("/myaccount");
      return;
    }

    try {
      //first check if user is logged in
      const token = AuthService.loggedIn() ? AuthService.getToken() : null;
      console.log(token);

      if (!token) {
        navigate(`/auth/${uuid}`);
        return;
      }

      const response = await fetch(`/api/nft/add-to-wallet/${uuid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to add NFT to wallet");
      }

      const result = await response.json();
      console.log(result.message);

      setIsAdded(true);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Button variant="contained" onClick={handleButtonClick}>
      {isAdded ? "View Wallet" : "Add to Online Wallet"}
    </Button>
  );
};

export default AddToWallet;
