import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container, Grid, Paper, Typography, Box, TextField, Button,
  createTheme, ThemeProvider, CssBaseline, CircularProgress
} from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppAppBar from '../../GeneralComponents/AppAppBar';
import Footer from './components/Footer';
import Theme from '../../GeneralComponents/Theme';
import AuthService from '../../clientUtils/auth';
import axios from 'axios';

const ItemTypes = {
  NFT: 'nft'
};

const NftItem = ({ nft, index, moveNft, listType }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.NFT,
    item: { nft, index, listType },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      elevation={3}
      sx={{
        p: 2,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <Typography variant="subtitle1">{nft.title}</Typography>
      <img src={nft.signedUrl} alt={nft.title} style={{ width: '100%', height: 'auto' }} />
    </Paper>
  );
};

const NftList = ({ nfts, listType, moveNft }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemTypes.NFT,
    drop: (item) => {
      if (item.listType !== listType) {
        moveNft(item.nft, item.listType, listType);
      }
    },
  }));

  return (
    <Box
      ref={drop}
      sx={{
        minHeight: 200,
        border: '1px dashed grey',
        padding: 2,
        backgroundColor: 'background.default'
      }}
    >
      {nfts.map((nft, index) => (
        <NftItem
          key={nft.uuid}
          nft={nft}
          index={index}
          moveNft={moveNft}
          listType={listType}
        />
      ))}
      {nfts.length === 0 && (
        <Typography align="center">
          {listType === 'userNfts' ? 'No other NFTs in wallet' : 'Drag NFTs here to offer'}
        </Typography>
      )}
    </Box>
  );
};

const TradingPage = () => {
  const [mode, setMode] = useState('light');
  const theme = createTheme(Theme(mode));
  const { nftId, tradeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [targetNft, setTargetNft] = useState(null);
  const [userNfts, setUserNfts] = useState([]);
  const [offeredNfts, setOfferedNfts] = useState([]);
  const [initialOfferedNfts, setInitialOfferedNfts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewingTrade, setIsViewingTrade] = useState(false);
  const [isCounterOffer, setIsCounterOffer] = useState(false);
  const [tradeDetails, setTradeDetails] = useState(null);
  const [globalTradeId, setGlobalTradeId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isInitiator, setIsInitiator] = useState(false);
  const [initiatorTradeableNfts, setInitiatorTradeableNfts] = useState([]);
  const [otherUserTradeableNfts, setOtherUserTradeableNfts] = useState([]);
  const [hasTradeId, setHasTradeId] = useState(false);
  

  useEffect(() => {
    console.log(tradeId);
    setGlobalTradeId(tradeId === "null" ? null : tradeId);
    const searchParams = new URLSearchParams(location.search);
    setIsInitiator(searchParams.get('initiator') === 'true');
  }, [tradeId, location.search]);

  useEffect(() => {
    if (globalTradeId !== null) {
      console.log(globalTradeId); // This will log after globalTradeId is updated
      setHasTradeId(true);
    }
  }, [globalTradeId]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(globalTradeId);
      setIsLoading(true);
      try {
        const token = AuthService.getToken();
        const userResponse = await AuthService.getProfile();
        setCurrentUser(userResponse);

        const [nftResponse, userNftsResponse] = await Promise.all([
          fetch(`/api/nft/individual/${nftId}`, { headers: { Authorization: `Bearer ${token}` } }),
          fetch('/api/user/myaccount/nfts', { headers: { Authorization: `Bearer ${token}` } })
        ]);

        if (!nftResponse.ok || !userNftsResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const [nftData, userData] = await Promise.all([
          nftResponse.json(),
          userNftsResponse.json()
        ]);

        console.log(userData);

        setTargetNft(nftData);

        // Filter tradeable NFTs
        const tradeableNfts = userData.savedNfts.filter(nft => nft.isTradeable === true);
        console.log(tradeableNfts);
        setInitiatorTradeableNfts(tradeableNfts);

        if (globalTradeId) {
          console.log(globalTradeId);
          const tradeResponse = await axios.get(`/api/trade/${globalTradeId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });

          console.log(tradeResponse.data.isCounterOffer);
          setTradeDetails(tradeResponse.data);
          setIsViewingTrade(true);
          setIsCounterOffer(tradeResponse.data.isCounterOffer);
          setOfferedNfts(tradeResponse.data.offeredNfts);
          setInitialOfferedNfts(tradeResponse.data.offeredNfts);

          // Update initiatorTradeableNfts to exclude offered NFTs
          const offeredNftIds = new Set(tradeResponse.data.offeredNfts.map(nft => nft.uuid));
          setInitiatorTradeableNfts(prevNfts =>
            prevNfts.filter(nft => !offeredNftIds.has(nft.uuid))
          );

          if (!isInitiator) {
            console.log("isInitiator:" + isInitiator);
            console.log("tradeResponse.data.initiator: " + tradeResponse.data.initiator);
            const otherUserId = tradeResponse.data.initiator;
            console.log(otherUserId);
            const otherUserNftsResponse = await axios.get(`/api/user/initiator/${otherUserId}/nfts`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            const otherUserNfts = otherUserNftsResponse.data.savedNfts.filter(nft => nft.isTradeable === true);
            console.log(otherUserNfts);

            const targetNftOwnerId = nftData.owner[0]._id;

            setOtherUserTradeableNfts(otherUserNfts.filter(nft => !offeredNftIds.has(nft.uuid) && !nft.owner.some(owner => owner._id === targetNftOwnerId)));
          }
        } else {
          console.log("no globalTradeId");
          setHasTradeId(false);
          // If no globalTradeId, fetch the logged-in user's tradeable NFTs
          setOtherUserTradeableNfts(tradeableNfts);
        }

        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Failed to fetch data: ${error.message}`);
        setInitiatorTradeableNfts([]);
        setTargetNft(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [globalTradeId, nftId, isInitiator]);

  useEffect(() => {
    if (isInitiator) {
      // Update initiatorTradeableNfts when offeredNfts changes
      const offeredNftIds = new Set(offeredNfts.map(nft => nft.uuid));
      setInitiatorTradeableNfts(prevNfts => {
        const updatedNfts = prevNfts.filter(nft => !offeredNftIds.has(nft.uuid));
        const newOfferedNfts = offeredNfts.filter(nft => !prevNfts.some(prevNft => prevNft.uuid === nft.uuid));
        return [...updatedNfts, ...newOfferedNfts];
      });
    } else {
      // Update otherUserTradeableNfts when offeredNfts changes
      const offeredNftIds = new Set(offeredNfts.map(nft => nft.uuid));
      setOtherUserTradeableNfts(prevNfts => {
        const updatedNfts = prevNfts.filter(nft => !offeredNftIds.has(nft.uuid));
        const newOfferedNfts = offeredNfts.filter(nft => !prevNfts.some(prevNft => prevNft.uuid === nft.uuid));
        return [...updatedNfts, ...newOfferedNfts];
      });
    }
  }, [offeredNfts, isInitiator]);


  const moveNft = (nft, sourceList, targetList) => {
    if (sourceList === targetList) return;

    if (sourceList === 'userNfts' && targetList === 'offeredNfts') {
      setOfferedNfts(prevOffered => [...prevOffered, nft]);
      if (isInitiator) {
        setInitiatorTradeableNfts(prevNfts => prevNfts.filter(item => item.uuid !== nft.uuid));
      } else {
        setOtherUserTradeableNfts(prevNfts => prevNfts.filter(item => item.uuid !== nft.uuid));
      }
    } else if (sourceList === 'offeredNfts' && targetList === 'userNfts') {
      setOfferedNfts(prevOffered => prevOffered.filter(item => item.uuid !== nft.uuid));
      if (isInitiator) {
        setInitiatorTradeableNfts(prevNfts => [...prevNfts, nft]);
      } else {
        setOtherUserTradeableNfts(prevNfts => [...prevNfts, nft]);
      }
    }
  };

  useEffect(() => {
    // Ensure no duplicates between initiatorTradeableNfts/otherUserTradeableNfts and offeredNfts
    const offeredNftIds = new Set(offeredNfts.map(nft => nft.uuid));
    if (isInitiator) {
      setInitiatorTradeableNfts(prevNfts => 
        prevNfts.filter(nft => !offeredNftIds.has(nft.uuid))
      );
    } else {
      setOtherUserTradeableNfts(prevNfts => 
        prevNfts.filter(nft => !offeredNftIds.has(nft.uuid))
      );
    }
  }, [offeredNfts, isInitiator]);

  useEffect(() => {
    // Ensure no duplicates between initiatorTradeableNfts and offeredNfts
    const offeredNftIds = new Set(offeredNfts.map(nft => nft.uuid));
    setInitiatorTradeableNfts(prevNfts => 
      prevNfts.filter(nft => !offeredNftIds.has(nft.uuid))
    );
  }, [offeredNfts]);

  useEffect(() => {
    // Ensure no duplicates between otherUserTradeableNfts and offeredNfts
    const offeredNftIds = new Set(offeredNfts.map(nft => nft.uuid));
    setOtherUserTradeableNfts(prevNfts => 
      prevNfts.filter(nft => !offeredNftIds.has(nft.uuid))
    );
  }, [offeredNfts]);

  const handleSubmitOffer = async () => {
    try {
      const token = AuthService.getToken();
      if (!targetNft.owner) {
        throw new Error('No owner information for the target NFT');
      }
      let ownerId = typeof targetNft.owner === 'string' ? targetNft.owner :
        (targetNft.owner.id || targetNft.owner._id ||
          (Array.isArray(targetNft.owner) && targetNft.owner[0] && (targetNft.owner[0].id || targetNft.owner[0]._id)));

      if (!ownerId) {
        throw new Error('Could not determine owner ID from the target NFT');
      }
      const chatResponse = await axios.post('/api/message/chats', {
        participantId: ownerId
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const chatId = chatResponse.data._id;
      const offeredNftIds = offeredNfts.map(nft => nft.uuid);
      const offerDetails = `Offered NFTs: ${offeredNftIds.join(', ')}`;
      await axios.post(`/api/message/chats/${chatId}/offers`, {
        nftId: targetNft.uuid,
        tradeDetails: offerDetails,
        isCounterOffer: isCounterOffer,
        tradeUuid: globalTradeId,
        offeredNfts: offeredNftIds
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate(`/chat`, { state: { activeChatId: chatId } });
    } catch (error) {
      console.error('Error submitting offer:', error);
      setError('Failed to submit offer: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleAcceptTrade = async () => {
    try {
      const token = AuthService.getToken();
      await axios.post(`/api/trade/${globalTradeId}/accept`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate(`/myaccount`);
    } catch (error) {
      console.error('Error accepting trade:', error);
      setError('Failed to accept trade: ' + (error.response?.data?.message || error.message));
    }
  };

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const isOfferChanged = () => {
    if (initialOfferedNfts.length !== offeredNfts.length) return true;
    const initialIds = new Set(initialOfferedNfts.map(nft => nft.uuid));
    const currentIds = new Set(offeredNfts.map(nft => nft.uuid));
    for (let id of initialIds) {
      if (!currentIds.has(id)) return true;
    }
    return false;
  };

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <CircularProgress />
          <Typography>Loading NFTs...</Typography>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>{isViewingTrade ? 'View Trade Offer' : 'Trade NFT'}</Typography>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>
        )}
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>{isViewingTrade ? 'They want your:' : 'NFT You Want'}</Typography>
                {targetNft && (
                  <Box>
                    <img src={targetNft.imageUrl} alt={targetNft.title} style={{ width: '100%', height: 'auto' }} />
                    <Typography variant="subtitle1">{targetNft.title}</Typography>
                    <Typography variant="body2">Series: {targetNft.series}</Typography>
                    <Typography variant="body2">Edition: {targetNft.individual}</Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 2, minHeight: 400 }}>
                <Typography variant="h6" gutterBottom>{isViewingTrade ? 'Their Offer' : 'Your Offer'}</Typography>
                <NftList nfts={offeredNfts} listType="offeredNfts" moveNft={moveNft} />
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleSubmitOffer}
                  sx={{ mt: 2 }}
                >
                  {isCounterOffer ? 'Submit Counter-Offer' : 'Submit Offer'}
                </Button>
                {!isInitiator && globalTradeId && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAcceptTrade}
                    sx={{ mt: 2 }}
                    disabled={isOfferChanged()}
                  >
                    Accept Trade Offer
                  </Button>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 2, minHeight: 400 }}>
                <Typography variant="h6" gutterBottom>{isViewingTrade ? 'Their Wallet' : 'Your NFTs'}</Typography>
                <NftList
                  nfts={isInitiator ? initiatorTradeableNfts : otherUserTradeableNfts}
                  listType="userNfts"
                  moveNft={moveNft}
                />
              </Paper>
            </Grid>
          </Grid>
        </DndProvider>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default TradingPage;