import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
} from '@mui/material';
import AuthService from '../../../clientUtils/auth';

const RedemptionSection = () => {
    const [redemptionValue, setRedemptionValue] = useState('');
    const [redemptionHistory, setRedemptionHistory] = useState([]); // Initialize as an empty array

    const [token, setToken] = useState("");

    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Check if the user is not an admin
        if (!AuthService.isAdmin()) {
            navigate("/forbidden"); // Redirect to the /forbidden page
        }

        const token = AuthService.loggedIn() ? AuthService.getToken() : null;
        if (!token) {
            navigate("/login");
        } else {
            setToken(token);
        }
    }, [navigate]);

    useEffect(() => {
        fetchRedemptionHistory();
    }, []);

    const fetchRedemptionHistory = async () => {
        try {
            // Check if the user is not an admin
            if (!AuthService.isAdmin()) {
                navigate("/forbidden"); // Redirect to the /forbidden page
            }

            const token = AuthService.loggedIn() ? AuthService.getToken() : null;
            if (!token) {
                navigate("/login");
            } else {
                setToken(token);
            }

            const response = await fetch('/api/redemption/history', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setRedemptionHistory(data);
            } else {
                console.error('Unexpected response format:', data);
                setRedemptionHistory([]);
            }
        } catch (error) {
            console.error('Failed to fetch redemption history', error);
            setRedemptionHistory([]); // Set to empty array on error
        }
    };

    const handleRedemptionSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!AuthService.isAdmin()) {
                navigate("/forbidden"); // Redirect to the /forbidden page
            }

            const token = AuthService.loggedIn() ? AuthService.getToken() : null;
            if (!token) {
                navigate("/login");
            } else {
                setToken(token);
            }


            const response = await fetch('/api/redemption/log-redemption', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ redemptionValue }),
            });
            if (response.ok) {
                setRedemptionValue('');
                fetchRedemptionHistory(); // Refresh the history
            } else {
                console.error('Failed to log redemption');
            }
        } catch (error) {
            console.error('Failed to log redemption', error);
        }
    };

    return (
    <Box component={Paper} p={2} mb={4}>
      <Typography variant="h6" gutterBottom>
        Redeem
      </Typography>
      <form onSubmit={handleRedemptionSubmit}>
        <TextField
          label="Redemption Value $"
          value={redemptionValue}
          onChange={(e) => setRedemptionValue(e.target.value.replace(/[^0-9.-]+/g, ""))} // Ensure numeric input
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
      <Typography variant="h6" gutterBottom mt={4}>
        Redemption History
      </Typography>
      <List>
        {redemptionHistory.map((redemption) => (
          <ListItem key={redemption._id}>
            <ListItemText
              primary={`$${parseFloat(redemption.redemptionValue).toFixed(2)}`}
              secondary={new Date(redemption.createdAt).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default RedemptionSection;