import React, { useState } from "react";
import { Button, Typography, TextField, Paper, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const VerificationSection = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [nft, setNft] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [title, setTitle] = useState("");
  const [series, setSeries] = useState("");
  const [individual, setIndividual] = useState("");
  const [purchasedAt, setPurchasedAt] = useState("");
  const [owner, setOwner] = useState("");
  const [isVerified, setIsVerified] = useState("");
  const [verifiedAt, setVerifiedAt] = useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const lookupNFT = async () => {
    try {
      const response = await fetch(`/api/admin/lookup/${verificationCode}`);
      if (!response.ok) {
        throw new Error("NFT not found");
      }
      const data = await response.json();
      setNft(data); // Save the entire NFT object

      // Convert and format dates from UTC to local timezone
      const createdAt = new Date(data.createdAt).toLocaleString();
      const verifiedAt = data.verifiedAt
        ? new Date(data.verifiedAt).toLocaleString()
        : "Not verified yet";

      // Populate fields with the data or placeholders for missing values
      setTitle(data.title || "No title");
      setSeries(data.series || "No series");
      setIndividual(data.individual || "No individual number");
      setPurchasedAt(createdAt || "Not purchased");
      setOwner(
        data.owner && data.owner.length > 0
          ? `${data.owner[0].firstName} ${data.owner[0].lastName}`
          : "Not yet claimed by owner."
      );
      setIsVerified(
        data.verified ? "Purchase verified" : "Purchase not verified"
      );
      setVerifiedAt(verifiedAt);
    } catch (err) {
      setSnackbarMessage(err.message);
      setSnackbarOpen(true);
      setNft(null);
      // Reset fields if the lookup fails
      setTitle("");
      setSeries("");
      setIndividual("");
      setPurchasedAt("");
      setOwner("");
      setIsVerified("");
      setVerifiedAt("");
    }
  };

  const verifyCode = async () => {
    try {
      const url = `/api/admin/verify/${verificationCode}`;
      const response = await fetch(url, {
        method: "PUT",
      });
      const data = await response.json();
      console.log(data.nft);

      setIsVerified(data.nft.verified ? "Is Verified" : "Not verified");
      setVerifiedAt(data.nft.verifiedAt || "Not verified yet");
      // Handle successful verification here, if needed
    } catch (err) {
      console.log("could not verify", err);
      // Optionally, show another snackbar for verification errors
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Lookup & Verification
      </Typography>
      <TextField
        label="Verification Code"
        variant="outlined"
        fullWidth
        margin="normal"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={lookupNFT}
        sx={{ mt: 2 }}
      >
        Lookup NFT
      </Button>
      {nft && (
        <>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Title:</strong> {title}
          </Typography>
          <Typography variant="body1">
            <strong>Series:</strong> {series}
          </Typography>
          <Typography variant="body1">
            <strong>Individual Number:</strong> {individual}
          </Typography>
          <Typography variant="body1">
            <strong>Purchased At:</strong> {purchasedAt}
          </Typography>
          <Typography variant="body1">
            <strong>Owner:</strong> {owner}
          </Typography>
          <Typography variant="body1">
            <strong>Verification Status:</strong> {isVerified}
          </Typography>
          <Typography variant="body1">
            <strong>Verified At:</strong> {verifiedAt}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={verifyCode}
            sx={{ mt: 2 }}
          >
            Verify
          </Button>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default VerificationSection;
