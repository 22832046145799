import React from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

export default function ResetPasswordReqest() {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState(false); // To differentiate between success and error messages

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    setLoading(true); // Start loading
    setMessage(""); // Reset message
    setError(false); // Reset error state

    try {
      await axios.post("/api/user/request-password-reset", { email });
      setMessage(
        "If an account exists for this email, a password reset link has been sent."
      );
      setLoading(false); // Stop loading
    } catch (error) {
      console.error("Failed to request password reset:", error);
      setMessage("Failed to request password reset. Please try again later.");
      setError(true); // Set error state
      setLoading(false); // Stop loading
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading} // Disable the button while loading
        >
          Send Reset Link
        </Button>
        {loading && (
          <Container sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Container>
        )}
      </form>
      {message && (
        <Typography color={error ? "error" : "primary"} textAlign="center">
          {message}
        </Typography>
      )}
    </Container>
  );
}
