import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => (
  <div>
    {/* Header, Navigation, etc, can go here */}
    <Outlet /> {/* Child routes get rendered here */}
    {/* Footer, etc, can go here */}
  </div>
);

export default Layout;