import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NftDownloadButton from "./components/DownloadButton";
// import GoogleWalletButton from "./components/GoogleWalletButton";
import AddToWallet from "./components/AddToCollection";
import AppAppBar from "../../GeneralComponents/AppAppBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Theme from "../../GeneralComponents/Theme";

export default function NftView() {
  const [mode, setMode] = useState("light");
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [seriesNumber, setSeriesNumber] = useState(0); // State for series number
  const [individualNumber, setIndividualNumber] = useState(0); // State for individual number
  const [expiresIn, setExpiresIn] = useState("");
  const [purchasedAt, setPurchasedAt] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [uuid, setUuid] = useState(0);
  const theme = createTheme(Theme(mode));
  const { series, id, token } = useParams();
  const navigate = useNavigate();

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const fetchNFTDetails = async () => {
      // console.log(token);
      try {
        const response = await fetch(`/api/nft/view/${series}/${id}/${token}`);
        if (!response.ok) {
          if (response.status === 403) {
            throw new Error("Failed to fetch NFT details");
          } else if (response.status === 404) {
            alert(
              "Access to this NFT has expired - More than 5 days has elapsed."
            );
            navigate("/");
          }
        }
        const data = await response.json();
        console.log(data);
        setImageUrl(data.signedUrl);
        setTitle(data.title.replace(/^"|"$/g, ""));
        setDescription(data.description.replace(/^"|"$/g, ""));
        setSeriesNumber(data.series);
        setIndividualNumber(data.individual);
        setUuid(data.uuid);
        setIsVerified(data.verified);
        setExpiresIn(data.verificationTokenExpires);
        startCountdown(data.verificationTokenExpires);

        const formattedPurchaseDate = new Date(data.createdAt);
        const formattedString =
          formattedPurchaseDate.toDateString() +
          " " +
          formattedPurchaseDate.getHours().toString().padStart(2, "0") +
          ":" +
          formattedPurchaseDate.getMinutes().toString().padStart(2, "0");

        setPurchasedAt(formattedString);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchNFTDetails();
  }, [series, id, token]);

  const startCountdown = (expiresTimestamp) => {
    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = expiresTimestamp - now;
      if (distance < 0) {
        setCountdown("Expired");
        clearInterval(interval);
        return;
      }
      // Calculate days, hours, minutes, and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    updateCountdown(); // Update immediately without waiting for 1 second delay
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval); // Cleanup on component unmount
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="NFT"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <Typography variant="h5">Product Image</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              {title || "Product Title"} {/* Display the title */}
            </Typography>

            {isVerified && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "green",
                  mt: 2,
                }}
              >
                <CheckCircleIcon sx={{ mr: 1 }} />
                <Typography variant="body1">Verified Purchase</Typography>
              </Box>
            )}
            <Typography variant="body1" gutterBottom>
              Series: {seriesNumber} {/* Display the series number */}
            </Typography>
            <Typography variant="body1">
              Edition: {individualNumber} {/* Display the individual number */}
            </Typography>
            <Typography variant="body1">{description}</Typography>

            {/* imageUrl={imageUrl} seriesNumber={seriesNumber} title={title} individualNumber={individualNumber} */}
            <NftDownloadButton imageUrl={imageUrl} title={title} uuid={uuid} />
            <AddToWallet uuid={uuid} />

            <Typography variant="body1" gutterBottom>
              Purchased: {purchasedAt}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Please download or add the nft to your wallet within the timeframe: {countdown}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
