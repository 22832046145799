import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  Container,
  Typography,
  ThemeProvider,
  createTheme,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppAppBar from '../../GeneralComponents/AppAppBar';
import Footer from './components/Footer';
import AuthService from '../../clientUtils/auth';
import Theme from '../../GeneralComponents/Theme';

const BackOfficePage = () => {
  const [mode, setMode] = useState('light');
  const theme = createTheme(Theme(mode));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); // Initialize as an empty array
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.loggedIn() ? AuthService.getToken() : null;
    console.log("Token:", token);
  
    if (!token) {
      console.log("No token found, navigating to login");
      navigate('/login');
      return;
    } else {
      setToken(token);
    }
  
    if (!AuthService.isSuperAdmin()) {
      console.log("User is not a super admin, navigating to forbidden");
      navigate('/forbidden');
      return;
    }
  
    const currentDate = new Date();
    const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
    const startOfWeek = new Date(currentDate.setDate(firstDayOfWeek));
    setStartDate(startOfWeek);
    fetchData(startOfWeek, new Date());
  }, [navigate]);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/back-office/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ startDate, endDate }),
      });
      const data = await response.json();
      setData(Array.isArray(data) ? data : []); // Ensure data is an array
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    fetchData(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    fetchData(startDate, date);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
              Admin Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  customInput={<TextField label="Start Date" />}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  customInput={<TextField label="End Date" />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Store Owner</TableCell>
                      <TableCell>Total Original Price</TableCell>
                      <TableCell>Total Tax</TableCell>
                      <TableCell>Total Redemption Value</TableCell>
                      <TableCell>Net Hold</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.user._id}>
                        <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                        <TableCell>{`$${parseFloat(
                          row.totalOriginalPrice
                        ).toFixed(2)}`}</TableCell>
                        <TableCell>{`$${parseFloat(row.totalTax).toFixed(
                          2
                        )}`}</TableCell>
                        <TableCell>{`$${parseFloat(
                          row.totalRedemptionValue
                        ).toFixed(2)}`}</TableCell>
                        <TableCell>{`$${(
                          parseFloat(row.totalOriginalPrice) -
                          parseFloat(row.totalRedemptionValue)
                        ).toFixed(2)}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default BackOfficePage;