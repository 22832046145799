import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './GeneralComponents/Layout.jsx';
import LandingPage from './pages/LandingPage/LandingPage.jsx';
import NftView from './pages/NftView/NftView.jsx';
// import Purchase from "./pages/Purchase/Purchase.jsx";
import SignUp from './pages/SignupPage/SignUp.jsx';
import Login from './pages/LoginPage/Login.jsx';
import ResetPassword from './pages/ResetPasswordPage/ResetPasswordPage.jsx';
import ResetPasswordRequest from './pages/ResetPasswordRequestPage/ResetPasswordRequestPage.jsx';
import UserProfile from './pages/UserProfile/UserProfile.jsx';
import Admin from './pages/Admin/Admin.jsx';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage.jsx';
import ForbiddenPage from './pages/ForbiddenPage/ForbiddenPage.jsx';
import Claim from './pages/Claim/Claim.jsx';
import BackOfficePage from './pages/BackOfficePage/BackOfficePage';
import MyBackOfficePage from './pages/MyBackOfficePage/MyBackOfficePage';
import BrowsePage from './pages/BrowsePage/BrowsePage.jsx';
import TradingPage from './pages/TradingPage/TradingPage.jsx';
import ChatPage from './pages/ChatPage/ChatPage.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="nft/:series/:id/:token" element={<NftView />} />
          {/* <Route path="purchase/" element={<Purchase />} /> */}
          <Route path="auth/:uuid?" element={<SignUp />} />
          <Route path="login/:uuid?" element={<Login />} />
          <Route path="myaccount/:uuid?" element={<UserProfile />} />
          <Route path="browse/" element={<BrowsePage />} />
          <Route path="/trade/:nftId/:tradeId" element={<TradingPage />} />
          <Route path="/chat/" element={<ChatPage />} />
          <Route path="admin/" element={<Admin />} />
          <Route path="back-office/" element={<BackOfficePage />} />
          <Route path="my-back-office/" element={<MyBackOfficePage />} />
          <Route path="claim/:code?" element={<Claim />} />
          <Route path="reset/:token" element={<ResetPassword />} />
          <Route path="reset-request/" element={<ResetPasswordRequest />} />
          <Route path="forbidden/" element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
