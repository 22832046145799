import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Button, Container, Divider, Typography, MenuItem, Drawer, Box, Badge } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AuthService from "../clientUtils/auth";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = AuthService.loggedIn();
  const isAdmin = isLoggedIn ? AuthService.isAdmin() : false;
  const isSuperAdmin = isLoggedIn ? AuthService.isSuperAdmin() : false;
  const isStoreOwner = isLoggedIn ? AuthService.isStoreOwner() : false;

  useEffect(() => {
    if (isLoggedIn) {
      fetchUnreadCount();
    }
  }, [isLoggedIn]);

  const fetchUnreadCount = async () => {
    try {
      const response = await fetch('/api/message/chats/unread-count', {
        headers: {
          'Authorization': `Bearer ${AuthService.getToken()}`
        }
      });
      const data = await response.json();
      setUnreadCount(data.count);
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  const logout = () => {
    AuthService.logout();
    navigate("/");
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const renderMenuItem = (to, text, count = null) => {
    const isActive = location.pathname === to;
    return (
      <MenuItem
        component={Link}
        to={to}
        sx={{ py: "6px", px: "12px", color: isActive ? 'primary.main' : 'text.primary' }}
      >
        <Typography variant="body2">
          {text}
        </Typography>
        {count !== null && count > 0 && (
          <Badge badgeContent={count} color="error" sx={{ ml: 1 }} />
        )}
      </MenuItem>
    );
  };

  const menuItems = (
    <>
      {isLoggedIn && !isSuperAdmin && renderMenuItem(isAdmin ? "/admin" : "/myaccount", isAdmin ? "Admin Utility" : "My Wallet")}
      {isLoggedIn && (isAdmin || isSuperAdmin) && renderMenuItem("/my-back-office", "My Back Office")}
      {isSuperAdmin && renderMenuItem("/back-office", "Back Office")}
      {isLoggedIn && renderMenuItem("/browse", "Catalogue")}
      {isLoggedIn && renderMenuItem("/chat", "Messages", unreadCount)}
    </>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "999px",
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              ml: "-18px",
              px: 0,
            }}
          >
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/nftsolutionslogo.png`}
                style={logoStyle}
                alt="logo of nft solutions"
              />
            </Link>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {menuItems}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            {isLoggedIn ? (
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
                <Button color="inherit" component={Link} to="/auth">
                  Sign up
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ display: { sm: "", md: "none" } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: "30px", p: "4px" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: "60dvw",
                  p: 2,
                  backgroundColor: "background.paper",
                  flexGrow: 1,
                }}
              >
                {menuItems}
                <Divider />
                {isLoggedIn ? (
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={logout}
                      sx={{ width: "100%" }}
                    >
                      Logout
                    </Button>
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/auth"
                        sx={{ width: "100%" }}
                      >
                        Sign up
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        component={Link}
                        to="/login"
                        sx={{ width: "100%" }}
                      >
                        Login
                      </Button>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]),
  toggleColorMode: PropTypes.func,
};

export default AppAppBar;