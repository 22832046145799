import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import AuthService from "../../clientUtils/auth";
import { useParams, useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Nft Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = React.useState("");

  let { uuid } = useParams();
  let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginDetails = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      const response = await axios.post("/api/user/login", loginDetails);
      if (response.data.token) {
        AuthService.login(response.data.token);

        const isAdmin = AuthService.isAdmin();
        console.log(`are you an admin? ${isAdmin}`);

        const isSuperAdmin = AuthService.isSuperAdmin();
        console.log(`are you a super admin? ${isSuperAdmin}`);

        if (!!uuid) {
          navigate(`/myaccount/${uuid}`);
        } else if (isSuperAdmin == true) {
          navigate("/back-office");
        } else if (isAdmin === true) {
          navigate("/admin");
        } else {
          navigate("/");
        }
        
      } else {
        console.error("Login failed: No token received");
        // Handle login failure (e.g., show error message)
        const message =
          "Could not log you in at this time. Please try again later";
        setErrorMessage(message);
      }
    } catch (error) {
      console.error(
        "Login failed:",
        error.response ? error.response.data : "No response"
      );
      const message =
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : "Please double-check your email and/or password.";
      setErrorMessage(message);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {errorMessage && (
              <Typography color="error" textAlign="center">
                {errorMessage}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
                <RouterLink
                  to="/reset-request"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body2">Forgot password?</Typography>
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink
                  to={uuid ? `/auth/${uuid}` : "/auth"}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="body2">
                    Don't have an account? Sign up
                  </Typography>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
