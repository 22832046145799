import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
// import IconButton from "@mui/material/IconButton";
// import Link from "@mui/material/Link";
import { Link as RouterLink } from 'react-router-dom';
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

// import FacebookIcon from "@mui/icons-material/GitHub";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/X";

// const logoStyle = {
//   width: "140px",
//   height: "auto",
// };

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <RouterLink to="/">NFT Solutions&nbsp;</RouterLink>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}
