import { jwtDecode as decode } from "jwt-decode";

// create a new class to instantiate the auth methods for the user
class AuthService {
  // retrieve user data saved in token
  getProfile() {
    return decode(this.getToken());
  }
 
  isAdmin() {
    // First, retrieve the token
    const token = this.getToken();
    // If there is no token, the user can't be an admin
    if (!token) return false;

    try {
      // Decode the token to get the user data
      const decoded = decode(token);
      // Check if the isAdmin attribute exists and is true
      return decoded.data.isAdmin === true;
    } catch (error) {
      console.error("Error decoding the token in isAdmin: ", error);
      return false;
    }
  }

  isStoreOwner() {
    // First, retrieve the token
    const token = this.getToken();
    // If there is no token, the user can't be an admin
    if (!token) return false;

    try {
      // Decode the token to get the user data
      const decoded = decode(token);
      // Check if the isAdmin attribute exists and is true
      return decoded.data.isStoreOwner === true;
    } catch (error) {
      console.error("Error decoding the token in isStoreOwner: ", error);
      return false;
    }
  }

  isSuperAdmin() {
    // First, retrieve the token
    const token = this.getToken();
    // If there is no token, the user can't be an admin
    if (!token) return false;

    try {
      // Decode the token to get the user data
      const decoded = decode(token);
      // Check if the isAdmin attribute exists and is true
      return decoded.data.isSuperAdmin === true;
    } catch (error) {
      console.error("Error decoding the token in isSuperAdmin: ", error);
      return false;
    }
  }

  // check if the user is still logged in
  loggedIn() {
    // check if there is a saved token and it's still valid
    const token = this.getToken();
    // use type coersion to check if token is NOT undefined and the token is NOT expired
    return !!token && !this.isTokenExpired(token);
  }
  // check if the token has expired
  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("expired check failed! Line 44: auth.js");
      return false;
    }
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }

  login(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
    // window.location.assign("/");
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
    // this will reload the page and reset the state of the application
    // window.location.assign("/");
  }
}

export default new AuthService();
