import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import NftCard from "./components/NftCard";
import Hero from "./components/Hero";
import AuthService from "../../clientUtils/auth";
import AppAppBar from "../../GeneralComponents/AppAppBar";
import Footer from "./components/Footer";
import Theme from "../../GeneralComponents/Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function UserProfile() {
  const [nfts, setNfts] = useState([]);
  const theme = createTheme(Theme("light"));

  let { uuid } = useParams();

  useEffect(() => {
    fetchNfts();
  }, [uuid]);

  const fetchNfts = async () => {
    const token = AuthService.getToken(); 
    try {
      const endpoint = uuid ? `/api/user/myaccount/nfts/${uuid}` : "/api/user/myaccount/nfts";
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch NFTs");
      }
      const data = await response.json();
      setNfts(data.savedNfts);
    } catch (error) {
      console.error("Error fetching NFTs:", error);
    }
  };

  const onUpdateNft = (updatedNft) => {
    setNfts(prevNfts => 
      prevNfts.map(nft => 
        nft._id === updatedNft._id ? updatedNft : nft
      )
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar />
      <Hero />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Grid container spacing={2}>
          {nfts.map((nft) => (
            <Grid item xs={12} key={nft._id}>
              <NftCard nft={nft} onUpdateNft={onUpdateNft} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}