import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  CssBaseline,
  Container,
  ThemeProvider,
  createTheme,
  Button,
  Box,
  TextField,
  Snackbar
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AppAppBar from "../../GeneralComponents/AppAppBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Theme from "../../GeneralComponents/Theme";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Claim() {
  const [mode, setMode] = useState("light");
  const theme = createTheme(Theme(mode));
  const { code } = useParams(); // Extract code from URL params
  const [claimCode, setClaimCode] = useState("");
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Autofill the claim code if it exists in the URL params
  useEffect(() => {
    if (code) {
      setClaimCode(code);
    }
  }, [code]);

  const handleClaim = async () => {

    let claimCodeToUse = claimCode || code;

    try {
      const response = await fetch(`/api/nft/claim/${claimCodeToUse}`);
      if (!response.ok) {
        // Check if response status is not OK (e.g., 404, 500)
        throw new Error(
          "Please double check your code."
        );
      }
      const data = await response.json();
      console.log(data);
      console.log(data.series);
      console.log(data.individual);
      console.log(data.verificationToken);
      navigate(`/nft/${data.series}/${data.individual}/${data.verificationToken}`);
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar
        mode={mode}
        toggleColorMode={() =>
          setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
        }
      />
      <Hero />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          <TextField
            label="Enter Code to Claim"
            variant="outlined"
            value={claimCode}
            onChange={(e) => setClaimCode(e.target.value)}
            inputProps={{ maxLength: 6 }}
            sx={{ mb: 2, width: "100%" }}
          />
          <Button variant="contained" onClick={handleClaim}>
            Claim
          </Button>
        </Box>
      </Container>
      <Footer />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
