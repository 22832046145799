import React, { createContext, useContext, useState, useEffect } from 'react';

const PrintJobsContext = createContext();

export const PrintJobsProvider = ({ children }) => {
  const [printJobs, setPrintJobs] = useState([]);

  useEffect(() => {
    const loadedPrintJobs = JSON.parse(localStorage.getItem("printJobs")) || [];
    setPrintJobs(loadedPrintJobs);
  }, []);

  const addPrintJob = (printJob) => {
    const updatedPrintJobs = [printJob, ...printJobs].slice(0, 10); // Keep only the latest 10 print jobs
    setPrintJobs(updatedPrintJobs);
    localStorage.setItem("printJobs", JSON.stringify(updatedPrintJobs));
  };

  return (
    <PrintJobsContext.Provider value={{ printJobs, addPrintJob }}>
      {children}
    </PrintJobsContext.Provider>
  );
};

export const usePrintJobs = () => useContext(PrintJobsContext);