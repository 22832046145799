import React, { useRef, useState, useEffect } from "react";
import { PrintJobsProvider } from "../../clientUtils/PrintJobsContext";
import { useNavigate } from "react-router-dom";
import AuthService from "../../clientUtils/auth";
import {
  CssBaseline,
  Grid,
  Container,
  Typography,
  ThemeProvider,
  createTheme,
  Button,
  Box,
  Snackbar,
  Paper,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AppAppBar from "../../GeneralComponents/AppAppBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Theme from "../../GeneralComponents/Theme";
import PrintingSection from "./components/PrintingSection";
import VerificationSection from "./components/VerificationSection";
import PrintHistorySection from "./components/PrintHistorySection";
import RedemptionSection from "./components/RedemptionSection";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Admin() {
  const [mode, setMode] = React.useState("light");
  const theme = createTheme(Theme(mode));

  // const [imageUrl, setImageUrl] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const [nftCode, setNftCode] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Check if the user is not an admin
    if (!AuthService.isAdmin()) {
      navigate("/forbidden"); // Redirect to the /forbidden page
    }

    const token = AuthService.loggedIn() ? AuthService.getToken() : null;
    if (!token) {
      navigate("/login");
    } else {
      setToken(token);
    }
  }, [navigate]);

  let responseNftCode;

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const verifyCode = async () => {
    try {
      const url = `/api/admin/verify/${responseNftCode}`;
      console.log(url);
      const response = await fetch(url, {
        method: "PUT",
      });
      const data = await response.json();
      console.log(data);
    } catch (err) {
      console.log("could not verify", err);
    }
  };

  const fetchImageUrl = async () => {
    try {
      const response = await fetch(`/api/nft/serve-next`);
      const data = await response.json();
      console.log(data);
      const nftId = data._id;
      const responseImageUrl = data.imageUrl;
      const series = data.series;
      const individual = data.individual;
      console.log(data.verificationCode);
      setNftCode(data.verificationCode);
      responseNftCode = data.verificationCode;
      console.log(`responseNftCode: ${responseNftCode}`);
      const returnObject = {
        nftId,
        responseImageUrl,
        series,
        individual,
        responseNftCode,
      };
      return returnObject;
    } catch (error) {
      console.error("Failed to fetch signed URL", error);
    }
  };

  return (
    <PrintJobsProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Hero />
        <Container maxWidth="lg" sx={{ my: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <PrintingSection
                fetchImageUrl={fetchImageUrl}
                token={token}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VerificationSection nftCode={nftCode} />
            </Grid>
            {/* <Grid item xs={12}>
              <PrintHistorySection token={token} />
            </Grid> */}
            <Grid item xs={12}>
              <RedemptionSection />
            </Grid>
          </Grid>
        </Container>
        <Footer />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </PrintJobsProvider>
  );
}
